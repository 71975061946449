<template>
  <div class="app-main-content">
    <div class="header">
      <div class="header-top">
        <div class="header-title d-flex">
          <div class="name">项目计划</div>
          <div class="progress d-flex a-i-center">
            <div>全部交付物</div>
            <span class="percent">{{ issueSta.complete_percent }}%</span>
            <a-progress style="width: 163px; height: 23px;line-height: 23px" :showInfo="false" strokeColor="#24B47E" :strokeWidth="9" :percent="issueSta.complete_percent - 0" size="small" />
          </div>
          <div class="num">共{{ issueSta.delivery_totalcount }}条</div>
        </div>
        <div class="header-search">
          <div class="item">
            <a-input placeholder="搜索交付物ID，名称，交付内容" style="width: 250px" v-model="search">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>
          <div class="item">
            <a-button class="search" @click="getDeliveryList">查询</a-button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-left">
        <a-table class="content-table" :data-source="deliveryList" rowKey="oid" :customRow="customRow" :pagination="false">
          <a-table-column title="ID" data-index="seqid" width="70px"/>
          <a-table-column title="交付物名称" data-index="delivery_name" width="200px">
            <template #default="_, row">
              <span>
                <span class="delivery_type blue" v-if="row.delivery_cat_type === 'D'">文</span>
                <span class="delivery_type green" v-if="row.delivery_cat_type === 'P'">产</span>
                {{row.delivery_name}}
              </span>
            </template>
          </a-table-column>
          <a-table-column title="交付数量" data-index="delivery_count" width="100px"/>
          <a-table-column title="状态" data-index="delivery_status_name" width="70px">
            <template v-slot:default="scope">
              <span
                class="edp-status"
                :class="{
                  blue: scope === '待审核',
                  yellow: scope === '审核中',
                  green : scope === '已完成' || scope === '审核通过',
                }"
                v-if="scope"
              >
                {{ scope }}
              </span>
            </template>
          </a-table-column>
          <a-table-column title="完成度" data-index="complete_percent" width="85px">
            <template v-slot:default="scope">
              <div v-if="scope">{{scope}}%</div>
            </template>
          </a-table-column>
          <a-table-column title="交付者" data-index="delivery_user" width="140px">
            <template v-slot:default="scope, row">
              <div class="user" v-if="scope">
                <a-avatar :size="21" :src="row.delivery_user_pic"/>
                <div>{{ row.delivery_user_title ? scope + '-' + row.delivery_user_title : scope }}</div>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="审批者" data-index="last_auditor_name" width="140px">
            <template v-slot:default="scope, row">
              <div class="user" v-if="scope">
                <a-avatar :size="21" :src="row.last_auditor_pic"/>
                <div>{{ row.last_auditor_title ? scope + '-' + row.last_auditor_title : scope}}</div>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="审批时间" data-index="last_auditor_date"  width="110px"/>
          <a-table-column title="来源任务项" data-index="issue_name" width="220px"/>
          <a-table-column title="交付文件">
            <template v-slot:default="row">
              <div v-if="row.att?.file_path && row.att?.file_path !== 'APPNULL'" style="color: #36A3FF;cursor: pointer;" @click="$preview(row.att, true)">
                {{row.att.file_name}}{{row.att.file_type}}
              </div>
            </template>
          </a-table-column>
        </a-table>
      </div>
      <div class="content-right edp-right-detail" style="--detailWidth: 420px">
        <project-right-detail-modal ref="RightDetail" :right-data="rightData" :logOid="rightData.oid"></project-right-detail-modal>
      </div>
    </div>
  </div>
</template>

<script>
import projectRightDetailModal from "@/components/projectRightDetail";
import {fetch} from "@/utils/request";

export default {
  name: "projectPlanDeliverable",
  data() {
    return {
      search: '',
      deliveryList: [],
      rightData: {},
      issueSta: {
        delivery_totalcount: '',
        complete_percent: '',
        delivery_finish: ''
      }
    }
  },
  components: {
    projectRightDetailModal
  },
  created() {
    let {prj_oid} = this.$route.query
    this.prj_oid = prj_oid
  },
  mounted() {
    this.getDeliveryList()
    this.getIssueSta()
  },
  methods: {
    getDeliveryList() {
      fetch('post', '/prj/delivery/list', {
        prjoid: this.prj_oid,
        keywords: this.search
      }).then((res) => {
        if (res.status === 0) {
          console.log(res.data);
          this.deliveryList = res.data
          if (this.deliveryList.length > 0) this.rightData = this.deliveryList[0]
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getIssueSta() {
      fetch('post', '/prj/delivery/sta', {
        prjoid: this.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          console.log(res.data);
          this.issueSta = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    customRow(row) {
      return {
        on: {
          click: () => {
            console.log(row);
            this.rightData = row
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.delivery_type {
  display: inline-block;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  line-height: 23px;
  text-align: center;
  font-size: 14px;
  background-color: #cacaca;
  color: white;
  margin-right: 3px;
  &.blue {
    background-color: #36A3FF;
  }
  &.green {
    background-color: #1cb969;
  }
}
.app-main-content {
  padding: 0 10px;
  //height: calc(100% - 50px);

  .header {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);
    border-radius: 4px;

    .header-top {
      box-sizing: border-box;
      height: 98px;
      border-bottom: 1px solid #CACACA;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;

      .header-title {
        flex-direction: column;
        font-size: 12px;
        color: #494949;
        font-weight: 500;

        .progress{
          font-size: 18px;
          font-weight: 500;

          .percent{
            color: #000000;
            margin: 0 12px 0 21px;
          }

          /deep/ .ant-progress-inner{
            background-color: #E8E8E8;
          }
        }

        > div{
          margin-bottom: 3px;
        }
        > div:nth-last-child(1){
          margin: 0;
        }
      }
      .header-search{
        display: flex;
        align-items: center;
        margin-left: auto;
        .item{
          margin-right: 20px;
          display: flex;
          align-items: center;

          &:nth-last-child(1){
            margin-right: 0;
          }

          .ant-btn.search{
            border-color: var(--primary-color);
            background-color: var(--primary-color);
            color: white;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 98px);
    padding-top: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .content-left {
      flex: 1;
      height: 100%;
      background-color: #FFF;
      overflow-y: auto;
      box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);
      .content-table {
        .user {
          font-size: 14px;
          color: #303030;
          display: flex;
          align-items: center;

          > .ant-avatar {
            margin-right: 3px;
          }
        }

        .status{
          border: 1px solid var(--primary-color);
          border-radius: 10px;
          font-size: 14px;
          color: var(--primary-color);
          padding: 2px 6px;
        }

        & .finish {
          border: 1px solid #2DB782;
          color: #2DB782;
        }
      }
    }

    .content-right {
      height: 100%;
      background-color: #FFF;
      margin-left: 13px;
      flex-shrink: 1;
      box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);
    }
  }
}
</style>
