<template>
  <div class="container">
    <a-tabs class="container-tabs" v-model="key" default-active-key="1">
      <a-tab-pane key="1" tab="动态">
      </a-tab-pane>
      <a-tab-pane key="2" tab="详情" v-if="isPro">
      </a-tab-pane>
      <a-tab-pane key="3" tab="审批" v-if="!isPro">
      </a-tab-pane>
    </a-tabs>
    <template v-if="key === '1'">
      <div class="tabs-content">
        <bizlog :source-oid="logOid"></bizlog>
      </div>
    </template>
    <template v-else-if="key === '2'">
      <div class="tabs-content">
        <div class="detail">
          <a-table :expandIcon="this.$customExpandIcon" :data-source="proBomList" :pagination="false" rowKey="oid">
            <a-table-column title="名称" data-index="node_name">
              <template  v-slot:default="_, row">
                {{ row.node_name }}
              </template>
            </a-table-column>
            <a-table-column title="计划" width="90px" data-index="schedule_count"/>
            <a-table-column title="实际" width="90px" data-index="actual_count"/>
            <a-table-column title="负责人" width="90px" data-index="delivery_name"/>
          </a-table>
        </div>
      </div>
    </template>
    <template v-if="key === '3'">
      <div class="tabs-content">
        <approval style="margin-top: 20px" :approval-data="rightData"></approval>
      </div>
    </template>
  </div>
</template>

<script>
import {fetch} from "@/utils/request";
import bizlog from "@/components/bizlog.vue";
import approval from "@/components/approval.vue";
export default {
  name: "projectRightDetailModal",
  props: {
    rightData: {
      type: Object
    },
    logOid: {
      type: String
    }
  },
  data(){
    return {
      key: "1",
      proBomList: [],
      isPro: false
    }
  },
  watch:{
    rightData: {
      deep: true,
      immediate: true,
      handler () {
        if (Object.keys(this.rightData).length > 0) {
          this.key = "1"
          if (this.rightData.delivery_cat_type === 'P') {
            let {prjoid, obj_oid: prooid, oid: delivery_oid} = this.rightData
            // if (!prooid) {
            //   this.isPro = false
            //   return
            // }
            this.getProBomList(prjoid, prooid, delivery_oid)
            this.isPro = true
          } else {
            this.isPro = false
          }
        }
      }
    }
  },
  components: {bizlog, approval},
  created() {
  },
  mounted() {

  },
  methods: {
    deepChild(list) {
      return list.map(item => {
        return {
          ...item,
          children: item.children.length ? this.deepChild(item.children) : undefined
        }
      })
    },
    // 查询产品boom列表
    getProBomList(prjoid, prooid, delivery_oid) {
      fetch('post', '/prj/product/bom', {
        prjoid,
        prooid,
        delivery_oid
      }).then((res) => {
        if (res.status === 0) {
          console.log(res.data);
          this.proBomList = this.deepChild(res.data)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    showApprovalModal(item, step_status) {
      this.$refs.approvalModal.visible = true
      let data = {...item, step_status}
      this.$refs.approvalModal.query = data
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);
  display: flex;
  flex-direction: column;
  .container-tabs {
    flex-shrink: 0;
    /deep/ .ant-tabs-nav-wrap {
      padding-left: 7px;
    }

    /deep/ .ant-tabs-nav .ant-tabs-tab {
      margin: 0;
    }

    /deep/ .ant-tabs-bar {
      border-bottom: 1px solid #e8e8e8;
      margin: 0;
    }
  }
  .tabs-content {
    overflow-y: auto;
    overflow-x: auto;
    /deep/.ant-table-body {
      .ant-table-tbody {
        td {
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
